import React, {Component} from 'react';
import PublicPage from '../common/containers/PublicPage';
import {withNamespaces} from 'react-i18next';
import Content from '../common/containers/Content';
import Row from '../common/containers/Row';
import Col from '../common/containers/Col';

class Privacy extends Component {
    render() {
        const {i18n} = this.props;
        return (
            <PublicPage title={i18n.t('Política de Privacidade')}>
                <Content>
                    <Row>
                        <Col md={8} mdOffset={2}>
                            <h3>Política de Privacidade</h3>

                            <p>Data da última modificação: 29 de março de 2021.</p>

                            <p>Para que você possa começar a utilizar de nossos produtos, se faz necessário que esta
                            política seja lida de forma minuciosa e aceita de forma livre, para que possamos oferecer a
                            melhor experiência com nossos produtos e serviços, queremos garantir ainda que você entenda
                            como estão sendo tratadas as suas informações pessoais.</p>

                            <p>Concordando com o que está elencado nesta, a qual é complementada pelos Termos de Uso do
                            nosso site, você poderá começar a usufruir dos nossos produtos. A FastFormat respeita os
                            seus usuários e, por isso, tem um sério compromisso de que toda a sua coleta de dados seja
                            pautada nos princípios da finalidade, necessidade, transparência, segurança, prevenção, não
                            discriminação, qualidade dos dados, adequação, livre acesso e prestação de contas. Como
                            também preservando a privacidade daqueles que navegam e/ou se cadastram pelo seu site.</p>

                            <p>Os dados pessoais que serão tratados pela FastFormat são os seguintes:</p>

                            <h4>Tipo de dado pessoal</h4>

                            <p>Nós coletamos os dados estritamente necessários para que possamos oferecer a melhor
                            experiência em nosso site.</p>

                            <p>Os dados coletados são: nome, e-mail, celular, data de nascimento, curso que está fazendo,
                            ID do computador, RG, CPF e endereço.</p>

                            <p>Coletamos seu nome para identificar e autenticar você, evitando ainda possíveis fraudes,
                            proteção ao crédito e riscos associados. Como também para emissão de nota fiscal.</p>
                            <p>Coletamos esses dados também para que você entre em nossa lista de contatos, e para que
                            possamos oferecer a você sempre o nosso melhor.</p>
                            <p>Coletamos o seu ID para saber apenas de onde é o usuário, não para fazer nenhuma outra ação
                            específica.</p>
                            <p>Em algum momento esses dados pessoais poderão ser utilizados para contatar você com ofertas
                            de produtos sobre temas relacionados ao conteúdo que você solicitou, serviços de seu
                            interesse e que estejam estritamente ligados com a utilização deste site.
                            Os dados pessoais coletados não serão compartilhados.</p>
                            <p>Você concorda que, em algum momento, poderá ser solicitado novos dados, para que seja
                            oferecida uma experiência transparente e segura, sem que seja desviada a finalidade do
                            tratamento deste dado já especificada e consentida pelo usuário.
                            Caso você não queira fazer parte da nossa lista de contatos, não tem problema, o usuário
                            poderá encaminhar um e-mail para encarregadodados@fastformat.com.br, solicitando a exclusão
                            dos seus dados.</p>
                            <p>Além dos dados pessoais informados acima, o aplicativo poderá coletar dados por outros meios
                            como por cookies (definição abaixo). Qualquer uso de cookies ou mesmo de outras ferramentas
                            de rastreamento pelo aplicativo servem para identificar os usuários e lembrar suas
                            preferências, com o único propósito de fornecer os serviços requeridos por eles e
                            proporcionar uma melhor navegação. Antes de iniciarmos a coleta pelos cookies, será exibida
                            uma mensagem com o link para a nossa política de cookies, que clicando nele você aceitará ou
                            não esta coleta.</p>
                            <p>No momento da sua navegação, no site, poderão ser utilizados alguns tipos de cookies, não se
                            limitando apenas a estes citados:</p>

                            <p>*Cookies necessários: são cookies estritamente necessários para a operação de um site ou
                            serviço compatível da FastFormat, eles permitem que você navegue pelo site e use nossos
                            recursos.</p>
                            <p> *Cookies de autenticação: são cookies que servem para reconhecer um determinado usuário e
                            possibilitar ao mesmo o acesso e a utilização de serviços e conteúdos restritos no site.</p>

                            <p>A Política de Privacidade da FastFormat está de acordo com a Constituição Federal
                            Brasileira, a Lei Geral de Proteção de Dados, o Marco Civil da Internet e as demais normas
                            regulamentadoras e de Proteção do Consumidor.</p>

                            <h3>Informações pessoais</h3>

                            <p>Você precisará criar uma conta para acessar o FastFormat. Ao se inscrever no serviço,
                            solicitaremos algumas informações pessoais, citadas acima, para criar uma Conta no
                            FastFormat, incluindo seu endereço de e-mail e uma senha, que será usada para proteger sua
                            conta contra acessos não-autorizados.</p>

                            <p>Quando você convida novos membros a participarem do FastFormat ou envia mensagens pelo
                            FastFormat, as informações associadas às mensagens são coletadas e armazenadas, incluindo os
                            endereços de e-mail e o conteúdo.</p>

                             <h4>Usos</h4>

                            <p>O FastFormat mantém e processa suas informações pessoais para lhe fornecer uma conta e o
                            acesso aos serviços do sistema. As informações do seu perfil são exibidas de acordo com as
                            preferências definidas na sua conta.</p>

                            <p>Quando você envia mensagens pelo FastFormat, o seu nome e endereço de e-mail aparecem na
                            mensagem. Também usamos o seu nome e endereço de e-mail para notificá-lo sobre novos
                            membros, mensagens ou outras informações e avisos de interesses profissionais em comum.</p>

                            <h4>Suas opções como usuário</h4>

                            <p>O FastFormat fornece diversas ferramentas para restringir as pessoas que podem ver o seu
                            perfil e outras informações pessoais. Você pode atualizar o seu perfil a qualquer momento.</p>

                            <p>As mensagens que você recebe do FastFormat são enviadas para o seu endereço de e-mail
                            cadastrado no site.</p>

                            <p>Você pode encerrar sua conta a qualquer momento. Se você encerrar sua conta, seu perfil,
                            incluindo quaisquer documentos, referências, modelos e mensagens contidas na sua conta,
                            serão removidos do site e excluídos dos servidores do FastFormat. Devido à maneira como a
                            manutenção deste serviço é feita, essa exclusão pode não ser imediata, e cópias residuais
                            dos dados do seu perfil poderão permanecer em dispositivos de backup.</p>

                            <p>A FastFormat nao se responsabiliza por dados públicos do usuário; isto é, aquelas
                            informações sobre o usuário (como, por exemplo, nome, RG, CPF, e-mail, etc.) que - sem a
                            interferência direta da FastFormat, já foram fornecidas, captadas, divulgadas e/ou estejam
                            disponíveis em outras plataformas eletrônicas (sites, blogs, chats, redes sociais, etc.).</p>

                            <p>A qualquer momento, o usuário tem a faculdade de decidir pelo recebimento de informações da
                            FastFormat. Se preferir, pode solicitar o cancelamento do envio das mensagens, bem como,
                            solicitar esclarecimento sobre qualquer dúvida acerca da nossa Política de Privacidade e
                            Termos de Uso. Para tanto, você deve escrever para o e-mail
                            encarregadodados@fastformat.com.br, teremos um enorme prazer em lhe atender e sanar todas as
                            suas dúvidas.</p>

                            <h4>Direitos do Titular de Dados</h4>

                            <p>O usuário, ora titular de dados pessoais coletados pelo controlador deste site, nos termos
                            do art. 18 da Lei n. 13.709/2018, poderá a qualquer momento e mediante requisição obter:</p>

                            <p>I - confirmação da existência de tratamento;</p>
                            <p>II - acesso aos dados;</p>
                            <p>III - correção dos dados incompletos, inexatos ou desatualizados;</p>
                            <p>IV - anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratamentos
                            em desconformidade com o disposto na legislação vigente;</p>
                            <p>V - portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição
                            expressa e resguardados os segredo comercial e industrial, de acordo com a regulamentação do
                            órgão controlador;</p>
                            <p>VI - eliminação dos dados pessoais tratados com o consentimento do titular, exceto na
                            hipóteses previstas no art. 16 Lei n. 13.709/2018;</p>
                            <p>VII - informação das entidades públicas e privadas com as quais o controlador realizou uso
                            compartilhado de dados;</p>
                            <p>VIII - informação sobre a possibilidade de não fornecer consentimento e sobre as
                            consequências negativas;</p>
                            <p>IX - revogação do consentimento, nos termos do parágrafo 5, do art. 8 da Lei n. 13.709/208.</p>

                            <p>Qualquer dúvida entrar em contato pelo e-mail encarregadodados@fastformat.com.br, teremos o
                            maior prazer em atender você.</p>

                            <p>Valorizamos uma cultura de proteção de dados pessoais e por tal motivo estaremos sempre
                            abertos para ouvir as sugestões dos usuários.</p>
                        </Col>
                    </Row>
                </Content>
            </PublicPage>
        );
    }
}

export default withNamespaces()(Privacy);
